<!--
 * @Author: wangwei
 * @Date: 2020-12-29 16:05:36
 * @LastEditTime: 2023-01-16 21:31:49
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 充值
 * @FilePath: /java-pc/src/views/member/money/recharge.vue
-->
<template>
    <div class="sld_AccountNow">
        <MemberTitle memberTitle="开通钱包"></MemberTitle>
        <div class="sld_bankCard_con">
            <!-- 《产业结算通协议》 -->
            <div class="agreement_content" v-show="isActive">
                <div class="hed">
                    <h2>《平安银行“产业结算通”会员服务协议》</h2>
                    <div class="last">（系统在线签署确认）</div>
                </div>
                <div class="ints">甲方：平安银行股份有限公司</div>
                <div class="ints">乙方: {{ memberTrueName }}</div>
                <div class="title">【特别提示】</div>
                <div class="ints">&nbsp;&nbsp;一、本协议有助于乙方了解平安银行“平安结算通”业务为乙方提供的服务内容及乙方使用该服务的权利和义务，请乙方确认在申请办理服务前已仔细阅读并充分理解《平安银行“平安结算通”商户服务协议》的全部条款，特别是其中所涉及的免除甲方责任和限制乙方权利的条款，请特别关注加粗内容。</div>
                <div class="ints">&nbsp;&nbsp;二、乙方通过网络点击“签署”或“同意”即表示乙方已充分理解本协议的全部内容并接受本协议的约束。如乙方选择不接受本协议，乙方将无法使用平安银行在乙方所属特定平台上提供的“平安结算通”商户相关服务。</div>
                <div class="ints">&nbsp;&nbsp;三、基于甲方与乙方所属特定平台的业务合作和签订的《平安银行“平安结算通”服务协议》，以及乙方与乙方所属特定平台签订的商户服务协议，甲方为乙方所属特定平台及乙方提供“平安结算通”相关服务。本协议由乙方与平安银行签署，本协议的签订视为乙方同意甲方向乙方提供本协议项下相关服务，并充分知悉和理解了本协议项下各方（甲方、乙方、乙方所属特定平台）的权利义务。</div>
                <div class="ints">&nbsp;&nbsp;四、甲方根据本协议为乙方开设平台商户编号用于记录乙方在乙方所属特定平台的资金交易数据，如甲方与乙方所属特定平台签署的《平安银行“平安结算通”服务协议》失效，甲乙任一方均有权解除本协议。</div>
                <div class="bold ints">&nbsp;&nbsp;五、本协议项下，平安银行（甲方）仅是根据协议约定为乙方提供平台商户分账台账开立、台账记账管理、资金额算、查询、对账等支付结算类服务。</div>
                <div class="bold ints">&nbsp;&nbsp;1、乙方在甲方开设的平台商户分账台账，用于记录乙方在乙方所属特定平台的资金交易数据，该台账不属于银行存款账户或银行结算账户或银行电子账户，乙方与甲方之间并不构成实质意义上的存款法律关系。甲方仅根据乙方及其所属平台发送的指令进行相关记账业务的处理。</div>
                <div class="bold ints">&nbsp;&nbsp;2、甲方不提供任何含有甲方对乙方及其平台所涉交易资金进行资金监管、资金托管等在内的服务，甲方也未委托乙方所属平台代理甲方进行理财销售等金融活动。</div>
                <div class="bold ints">&nbsp;&nbsp;3、甲方提供的本协议项下服务，不应被视为对乙方及其所属特定平台和其他相关方本身、交易本身以及其他相关行为提供任何形式的承诺或保证\担保。</div>
                <div class="bold ints">&nbsp;&nbsp;4、乙方已明确知晓甲方按照乙方所属特定平台发送申请或指令为乙方提供资金结算业务；甲方不对乙方在其所属特定平台进行的交易提供任何形式的保证或承诺，乙方须自行承担交易责任和风险。平安银行不对乙方通过充值交易、支付交易等任何方式投入的交易资金本金及可能发生的本金受损、交易失败、交易纠纷引起的交易资金受损、或乙方购买平台自行推出或与其他第三方联合推出的各类商品和服务（含各类理财、资金增值类服务等）而引起的交易资金受损等承担任何形式的保证或承诺，不承担乙方及乙方所属特定平台对交易资金管理运用和处分而引起的任何风险或责任，乙方须自行承担责任和风险。</div>
                <div class="bold ints">&nbsp;&nbsp;5、如因涉及乙方或乙方所属特定平台利用甲方系统从事违反国家法律法规活动（包括但不限于涉及赌博、电信诈骗等），或因涉及乙方或乙方所属特定平台的交易主体与交易真实合法性、商品和服务纠纷等问题引起的第三方侵权，甲方因此发生损失的，乙方承担连带赔偿责任。如有上述非法行为且涉及电信网络诈骗犯罪活动的，甲方将移交公检法等有权机关处理。</div>
                <div class="bold ints">&nbsp;&nbsp;乙方完全同意和接受本协议的全部条款和内容，愿意履行和承担本协议中约定的权利和义务。具体业务规则请见附件一，涉及相关释义、服务内容等内容。</div>
                <div class="bold ints">第一条  权利与义务</div>
                <div class="ints">（一）甲方权利义务</div>
                <div class="ints">1. 甲方有权定期或不定期审核乙方的业务类型，如乙方由于单方变更业务类型等原因导致提供不符合本协议约定或国家相关法律法规规定的业务，甲方有权暂停或终止提供本协议项下服务并无需承担任何责任。如给甲方造成损失的，乙方须赔偿因相关行为给甲方造成的损失。</div>
                <div class="ints">2. 乙方授权甲方从乙方所属特定平台获取并保存、使用乙方在乙方所属特定平台的交易数据和乙方提供的数据信息，用于对乙方交易真实性和合法性的甄别以及反洗钱报送等合法用途。甲方有权对乙方采取身份识别、银行结算账户识别、风险监测及动态评分等风控措施。</div>
                <div class="bold ints">3. 乙方发生以下任一情况，甲方有权要求乙方配合提供乙方在平台的交易数据和其他数据信息、资料影像件等信息进行核查，甲方有权协同乙方所属特定平台采取核查、延迟资金结算、限制资金结算金额、暂停或终止本协议项下的服务和报送有关监管部门及公检法部门等所有甲方认为有必要的措施而无需承担任何责任，无需事先征得乙方同意：</div>
                <div class="ints">（1）乙方被吊销营业执照，或因任何原因进入破产清算、和解或重整程序。</div>
                <div class="ints">（2）乙方在甲方系统内发生身份或银行结算账户识别异常。</div>
                <div class="ints">（3）乙方利用甲方提供的服务从事赌博、洗钱、套现、传销、淫秽色情等非法活动或为非法活动提供协助及便利的。</div>
                <div class="ints">（4）乙方被清算协会、清算机构纳入黑名单的；由前述清算协会、清算机构纳入黑名单的个人担任法定代表人或者负责人的。</div>
                <div class="ints">（5）其他甲方认定的违反甲方产品管理规范，触发甲方风险预警情况的。</div>
                <div class="ints">4. 按照基于风险管理、运营管理或其他风险防控因素的要求，发现乙方涉嫌非法、违法犯罪的，甲方有权向公安机关报告。</div>
                <div class="bold ints">5. 甲方提供的服务受乙方所属特定平台相关业务账户的制约，如乙方所属特定平台相关业务账户因挂失、止付、冻结等原因不能使用，本协议项下相关服务将中止或终止，且甲方不承担任何责任。</div>
                <div class="bold ints">6. 甲方提供的服务受到乙方自身情况的制约，如乙方账号被其所属特定平台冻结等原因不能使用，或乙方绑定的银行结算账户因止付、冻结等原因不能使用，相关交易指令将中止，甲方不承担任何责任。</div>
                <div class="bold ints">7. 因不可归咎于甲方的原因导致乙方所属特定平台中止/终止使用甲方服务的，导致乙方无法完成收款、资金结算等相关服务的，甲方不承担任何责任，乙方承诺其与乙方所属特定平台自行协商解决。</div>
                <div class="ints">8. 甲方将根据甲方系统接收到的相关指令办理业务，业务处理时间以甲方在甲方系统中处理的时间为准。</div>
                <div class="bold ints">9. 甲方因以下任一情况没有正确执行乙方所属特定平台指令，造成乙方损失的，甲方不承担责任：</div>
                <div class="ints">（1）甲方接收到乙方所属特定平台的指令信息不明、存在乱码、不完整或无法辨认等；</div>
                <div class="ints">（2）乙方在乙方所属平台被依法冻结或被有权机关采取其它限制性措施；</div>
                <div class="ints">（3）乙方操作不当或操作失误；</div>
                <div class="ints">（4）由于乙方所属特定平台导致的其他错误情况；</div>
                <div class="ints">（5）不可抗力或其他不可归因于甲方过失的情况。</div>
                <div class="bold ints">10. 甲方仅接收通过乙方所属特定平台发送的指令，并根据乙方所属特定平台的订单信息进行资金清分。对乙方与乙方所属特定平台在交易过程中产生的一切纠纷，甲方不承担任何责任，由乙方与乙方所属特定平台双方自行解决。甲方存在明显过错的除外。</div>
                <div class="ints">11. 甲方有义务维护业务系统的安全平稳运行，但不可归咎于甲方原因的情形除外。</div>
                <div class="ints">12. 乙方在使用甲方服务过程中如有疑问，甲方有义务提供相应的咨询和支持。</div>
                <div class="ints">13. 甲方有权根据业务需要更新和升级甲方系统。</div>
                <div class="ints">14. 甲方确保经乙方授权获取的工商数据、经营数据、银行结算账户等数据信息，仅用于甲方及和甲方合作的第三方渠道对乙方进行身份、银行账户信息核验。</div>
                <div class="bold ints">15. 甲方有权在无法继续与乙方或乙方所属特定平台的合作时，按照乙方的待结算金额，资金自动结算至乙方绑定的银行账户。</div>
                <div class="bold ints">16. 乙方商户连续6个月无资金交易或信息上送的，甲方有权单方终止本协议项下服务。</div>
                <div class="ints">（二）乙方权利义务</div>
                <div class="ints">1. 乙方对通过乙方所属特定平台向甲方系统发送的电子业务指令所涉数据内容的真实性和有效性负责。对乙方与乙方所属特定平台、其他交易用户之间的纠纷，均与甲方无关，乙方承诺其与上述各相关方自行协商解决。</div>
                <div class="ints">2. 乙方应在安全环境中使用甲方系统和本协议项下服务，避免存在病毒、木马等危害性的程序，并对所使用的相关软件的合法性和安全性承担责任。乙方应妥善保管用户信息及相关密码，不提供给他人使用，并对任何人使用所导致的损失承担责任。如用户名、密码出现遗忘等情况，乙方应及时通知甲方冻结账户并重新申请；否则，由此造成的损失由乙方承担。</div>
                <div class="bold ints">3. 乙方在使用本协议项下服务过程中，所提供的资料信息如有更改，例如基本注册信息变更、预留手机号码变更、绑定的银行账号变更等，应及时按照甲方要求办理有关手续。办妥上述手续之前所产生的一切后果由乙方自行承担。</div>
                <div class="ints">4. 乙方使用本协议项下服务时，如其使用的服务功能涉及到甲方其他业务规定或规则的需同时遵守。</div>
                <div class="ints">5. 乙方授权通过其所属特定平台向甲方及甲方合作的第三方渠道提供数据以对乙方身份及账户等信息进行核验，乙方同意甲方对乙方提供的联系电话（包括单位和个人）进行实名查证。</div>
                <div class="bold ints">6. 乙方有义务确保在特定平台上的交易真实、合法、合规，因乙方违法违规造成的被甲方采取协同乙方所属特定平台核查、延迟资金结算、限制资金结算金额、禁止资金结算、暂停交易、暂停或终止本协议项下的服务、报送有关监管部门及公检法部门等所有甲方认为有必要的措施而带来的后果，均由乙方自行承担。</div>
                <div class="ints">7. 数字口袋是甲方为乙方提供的平台商户管理服务平台，乙方通过甲方核验后，甲方将为其开通数字口袋使用权限。乙方可通过手机号登录使用甲方“数字口袋”平台渠道（含APP、公众号、小程序等），使用包括但不限于台账记账查询、对账、分账明细单下载等移动端的金融服务。</div>
                <div class="bold ints">&nbsp;&nbsp;8. 乙方有义务确保不出租、出借、出售支付受理终端（含条码支付辅助受理终端）、收款条码、网络支付接口和收单结算账户，因乙方违法或违规的行为造成所产生的一切后果由乙方自行承担。</div>
                <div class="bold ints">&nbsp;&nbsp;9. 乙方承诺将严格遵守反洗钱、反恐融资及反逃税的相关规定，并根据甲方要求提供相关信息及资料，协助配合提供反洗钱交易监测及报送所需数据，并对信息及资料真实性、准确性及完整性负责。乙方知悉并同意，甲方为满足境内外各级监管机构的要求，履行相关法定义务，有权在不预先知会乙方的情况下采取反洗钱、反恐融资及反逃税相关必要措施，包括但不限于暂停或终止提供任何服务。乙方同意在甲方要求时协助甲方根据有关反洗钱、反恐融资、反逃税的相关规定采取的适当行动及调查，并认可甲方所采取的行动及调查不构成违约。</div>
                <div class="bold ints">第二条  实名认证及关联支付验证授权条款</div>
                <div class="ints">&nbsp;&nbsp;甲方依据乙方提供的信息，包括名称、证件号码、法定代表人名称、法定代表人证件号码（如有）、手机号码、账户信息等必要的信息，用于核实所输银行卡是否为用户本人银行卡，核验乙方身份信息是否准确。</div>
                <div class="ints">&nbsp;&nbsp;甲方负责收集上述个人信息，通过合作的合法机构（包括且不限于中国银联）提交发卡机构，发卡机构将上述个人信息用于一致性比对，通过合法机构返回核验结果。乙方可以通过中国银联云闪付APP 和发卡机构官网、APP 各自展示的个人隐私政策进一步了解其联系方式、个人信息的保存期限和用户行使个人信息权益的方式和程序等个人信息处理相关的内容。</div>
                <div class="bold ints">&nbsp;&nbsp;乙方同意并授权甲方合作的合法机构、发卡机构基于本协议核验身份之需在各自业务职责范围内采集、使用上述个人信息。</div>
                <div class="bold ints">第三条  声明条款</div>
                <div class="ints">（一）本协议独立于乙方与乙方所属特定平台双方通过各种方式签订的各类相关合同或协议，乙方与乙方所属特定平台之间所发生的任何争议、纠纷、诉讼、仲裁等，均与甲方无关，乙方承诺其与乙方所属特定平台自行协商解决。</div>
                <div class="ints">（二）如乙方对本协议存在任何意见或建议，可通过客服电话（投诉热线95511-3-8、95511-2-8（信用卡））、官方网站（http://bank.pingan.com）“在线客服”、平安口袋银行APP“在线客服”、平安数字口袋APP“在线客服”、投诉电子邮箱callcenter@pingan.com.cn，或平安银行各营业网点进行咨询和反馈。甲方受理乙方的问题后，将在时效内核实并提供解决方案。</div>
                <div class="bold ints">&nbsp;&nbsp;第四条  免责条款</div>
                <div class="ints">&nbsp;&nbsp;因下列原因导致甲方无法正常提供本协议项下服务的，甲方不承担任何责任：</div>
                <div class="ints">&nbsp;&nbsp;（一）甲方系统停机维护或升级；</div>
                <div class="ints">&nbsp;&nbsp;（二）因台风、地震、洪水、雷电或恐怖袭击等不可抗力原因；</div>
                <div class="ints">&nbsp;&nbsp;（三）用户的电脑软硬件和通信线路、供电线路出现故障的；</div>
                <div class="ints">&nbsp;&nbsp;（四）用户操作不当或通过非甲方授权或认可的方式使用甲方系统或本协议项下服务的；</div>
                <div class="ints">&nbsp;&nbsp;（五）因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、第三方服务瑕疵或监管要求等非甲方可控的原因。</div>
                <div class="bold ints">第五条  通知条款</div>
                <div class="ints">&nbsp;&nbsp;甲方有权依据法律、法规、规章或业务需要对本协议及各类规则不时进行调整或修改，涉及乙方权利义务变更的调整或修改，将以网站公示的方式进行公告，不再单独通知乙方。自公告施行之日公告内容构成对各方协议约定的有效修改和补充。如果乙方不同意接受调整或修改的内容，应当立即停止使用甲方本协议项下服务，甲方对此不承担任何法律责任和费用补偿。乙方继续使用甲方本协议项下服务的，即视为乙方接受经调整或修改的协议和规则。</div>
                <div class="bold ints">第六条  争议解决</div>
                <div class="ints">（一）本协议的订立、效力、履行和解释，均适用中华人民共和国法律；法律无明文规定的，可适用通行的交易惯例。未尽事宜，双方应友好协商或另行签订补充协议。</div>
                <div class="ints">（二）本协议各方在履行本协议过程中所发生的争议，由双方协商解决；协商不成的，按向甲方所在地有管辖权的人民法院提起诉讼的方式解决。</div>
                <div class="bold ints">第七条  协议的生效及效力</div>
                <div class="bold ints">（一）本协议由甲方通过乙方在其所属特定平台提供的系统服务界面操作确认的方式签署或者在甲方提供的渠道进行签署；该服务界面进行的任何形式的确认，包括但不限于数字证书、手机验证码、点击确认、电子签约等形式的确认，按IP地址、MAC地址、签约时间等任一软硬件信息记录下来的签约行为数据，均视为双方真实意思的表示；任何一方均承诺并确认：不以确认形式为由要求撤销本协议或者确认本协议无效或对本协议效力提出任何异议。本协议经双方通过上述方式确认同意签署本协议，于系统记录的签约日期之日起发生法律效力。乙方签约时记录的信息，包括且不限于短信验证码、IP地址等，将组成乙方执行签约动作的唯一性记录，用于证明乙方的签约。</div>
                <div class="ints">（二）除非本协议另有约定，两方中如果有其中一方需要提前终止本协议，需提前通知另外一方。甲方拟终止本协议项下服务或终止本协议的，将提前通过相关渠道进行公告或进行相应的提醒；在甲方明示的公告期或提醒期限内，乙方若无异议的，则本协议在甲方明示的公告期届满之日起或提醒期限届满之日起自动终止。</div>
                <div class="ints">&nbsp;&nbsp;附件一： 业务规则说明</div>
                <div class="ints">&nbsp;&nbsp;附件二： 保密条款</div>
                <div class="ints">&nbsp;&nbsp;附件三： 反商业贿赂与反虚假宣传条款</div>
                <div class="bold ints">附件一：业务规则说明</div>
                <div class="bold ints">第一条  相关释义</div>
                <div class="ints">&nbsp;&nbsp;除非另有约定，本协议所述下列术语释义如下：</div>
                <div class="ints">（一）“平安结算通”：是指在乙方及乙方所属特定平台成立、合法存续和持续经营符合国家法律法规和监管政策的前提下，甲方接受乙方所属特定平台的委托，为乙方所属特定平台及乙方提供支付结算等一体化金融服务。</div>
                <div class="ints">（二）乙方所属特定平台：是指乙方与乙方交易对手方达成交易或服务提供规则、交易场所、撮合服务等综合服务的平台型经营主体。</div>
                <div class="ints">（三）乙方“平台商户编号”/乙方分账台账：是指甲方为加强商户真实性、有效性、可持续性管理，基于乙方授权提供的身份信息，在经过甲方验证核实的前提下，由甲方为乙方开设的用于记录乙方交易数据的唯一编号。如甲方验证核实不通过，甲方将拒绝为乙方开设“平台商户编号”。</div>
                <div class="ints">（四）乙方待结算金额：是甲方根据乙方所属特定平台的指令，记录乙方交易数据对应的待结算款项金额。</div>
                <div class="ints">（五）资金结算：是指将乙方的待结算金额结算至其绑定的结算账户。</div>
                <div class="bold ints">第二条  服务内容</div>
                <div class="ints">&nbsp;&nbsp;甲方向乙方提供如下服务：</div>
                <div class="ints">（一）乙方“平台商户编号”的开设</div>
                <div class="ints">&nbsp;&nbsp;甲方支持为乙方开立“平台商户编号”用于支付结算等服务，在资金结算前，须绑定其合规结算账户、电子账户（其使用需符合监管要求）。该类绑定需经甲方审核同意；未经身份核验通过，甲方有权拒绝开设。</div>
                <div class="ints">（二）乙方的身份识别和核验</div>
                <div class="ints">&nbsp;&nbsp;乙方向甲方申请开设“平台商户编号”，甲方须通过其自身渠道或合作的第三方渠道，及包括但不限于系统、人工的核验方式，对乙方平台商户的身份和相关账户进行核验。</div>
                <div class="ints">（三）使用银行端身份验证密码服务</div>
                <div class="ints">&nbsp;&nbsp;基于乙方所属特定平台和乙方的申请，甲方可为乙方提供乙方银行端身份验证密码服务，即乙方可通过甲方提供的银行密令控件设置相应的密码，相关使用须经甲方系统的核验通过，该类密码由甲方以加密形式保存在甲方系统。密码仅在乙方修改其商户信息、银行预留信息及分账台账账务变化时使用。</div>
                <div class="ints">（四）关于银行与平台的交易对账</div>
                <div class="ints">&nbsp;&nbsp;乙方同意甲方通过乙方所属特定平台获取乙方在乙方所属特定平台发生的各类资金交易指令和乙方在甲方或第三方支付机构的对账单，并进行核验、对账。甲方与乙方所属特定平台对账无误后，按照约定进行所涉交易资金相应的系统处理。对账有误时，甲方将向包括但不限于乙方或乙方所属特定平台核实原因，修正错误前甲方可暂停执行所涉交易资金相应的系统处理。</div>
                <div class="bold ints">&nbsp;&nbsp;当乙方交易行为触发甲方风险交易规则时，甲方可能增加对乙方的身份核验（如生物信息识别等），核验结果有可能影响资金结算，包括但不限于暂停交易、资金冻结等。</div>
                <div class="ints">（五）商户交易服务</div>
                <div class="ints">&nbsp;&nbsp;甲方为乙方提供涉及乙方所属平台的交易资金结算服务。当乙方履行完相关责任义务后，交易对手方在乙方所属特定平台提供的服务渠道上进行确认支付。当乙方符合乙方所属特定平台相关交易及结算规则后，甲方将对应资金划转至乙方绑定的结算账户。当乙方单笔或日累计资金结算金额达到5万元（含）以上时，甲方可增加对乙方的身份核验（如生物信息识别、手势密码识别、再次核验身份信息等）环节，核验不通过时无法继续进行资金结算。</div>
                <div class="bold ints">第三条  关于银行端身份验证密码的使用</div>
                <div class="ints">（一）银行端身份验证密码的设置：乙方在乙方所属特定平台提供的服务渠道上进行注册、申请开设平台商户编号、进行银行账户绑定时，在经甲方核验通过的前提下，需要根据甲方系统的提示设置相应的银行端身份验证密码。</div>
                <div class="ints">（二）银行端身份验证密码的使用</div>
                <div class="ints">乙方修改信息及银行预留信息时，需根据甲方系统的提示输入正确的银行端身份验证密码，经核验通过后予以办理。</div>
                <div class="ints">（三）乙方应妥善保管自身信息及银行端身份验证密码、乙方所属特定平台端的密码（若有），并确保使用其交易密码的主体均为乙方或乙方授权的人员。因乙方保管不善导致密码泄露的，甲方不承担任何责任，应由乙方对所有可能导致的风险和损失承担责任。如出现密码泄露等情况，乙方应及时通知甲方禁止对乙方进行资金结算并重新申请设置新的银行端身份验证密码。对于银行端身份验证密码不正确的各类业务指令，甲方有权拒绝执行。
                    只要正确输入乙方设定的密码，并通过甲方系统的核验通过，即视为乙方自身或乙方授权发起的修改商户信息及银行预留信息。</div>
                <div class="bold ints">（四）若乙方所属特定平台决定不使用甲方提供的银行端身份验证密码服务，即在乙方通过其平台发起各类指令时，无需再输入银行端身份验证密码。乙方对于该类方式表示接受，同时承诺在其交易对应款项发生任何可能的损失时，均由乙方和乙方所属特定平台自行协商解决。</div>
                <div class="bold ints">附件二：保密条款</div>
                <div class="ints">一、甲乙双方对于本协议内容及因履行本协议而获知的另一方的商业和技术秘密负有保密义务，任何一方不得将本协议内容及已获知的另一方的商业和技术秘密泄露或以其他任何变相形式告知任何非本协议当事人（包括双方没有必要授权的任何雇员），或用于除本协议履行之外的其他目的，且乙方还应对甲方提供技术方案依法承担保密义务。但以下情况除外：</div>
                <div class="ints">（一）法律法规另有规定或有权机构或监管部门另有要求的；</div>
                <div class="ints">（二）在任何法律或仲裁程序中进行的披露；</div>
                <div class="ints">（三）甲方向甲方所在集团或平安银行分支机构进行披露；</div>
                <div class="ints">（四）甲方向所雇佣的专业咨询机构（包括但不限于律师和/或财务顾问）披露；</div>
                <div class="ints">（五）甲乙双方另有约定的可以披露的情形；</div>
                <div class="ints">（六）提供的资料不构成保密信息的。</div>
                <div class="ints">二、除本合同另有约定外，乙方同意甲方在法律法规许可范围内使用乙方的资料、用户信息和交易记录、交易支付记录等，并对乙方的银行端身份验证密码、乙方资金动态和相关交易情况严格保密，但上述第一条 （一）约定的情形除外。</div>
                <div class="ints">三、乙方通过甲方使用特定平台向甲方交互的与乙方有关的各类信息，甲方保证只用于本协议项下服务之目的，不用做其他用途，但上述第一条 （一）约定的情形除外。</div>
                <div class="ints">四、双方均应采取一切合理、必要的步骤，保证各自（及其雇员）遵守上述约定。双方应采取合理的预防措施以保护保密信息，此种预防措施应至少与对自己的保密信息所采取的措施一样重大，但不应少于合理的关注。</div>
                <div class="ints">五、未经对方书面同意，双方保证不得以召开新闻记者会或者其他任何方式披露关于双方正在合作及洽谈的内容。</div>
                <div class="ints">六、保密义务不受本协议有效期限的限制，本协议终止后，本条继续有效。</div>
                <div class="bold ints">附件三：反商业贿赂与反虚假宣传条款</div>
                <div class="ints">一、反商业贿赂条款</div>
                <div class="ints">（一）甲乙双方都清楚并愿意严格遵守中华人民共和国反商业贿赂的法律规定，双方都清楚任何形式的贿赂和贪渎行为都将触犯法律，并将受到法律的严惩。</div>
                <div class="ints">（二）甲方或乙方均不得向对方或对方经办人员或其他相关人员索要、收受、提供、给予合同约定外的任何利益，包括但不限于明扣、暗扣、现金、购物卡、实物、有价证券、旅游或其他非物质性利益等，但如该等利益属于行业惯例或通常做法，则须在合同中明示。</div>
                <div class="ints">（三）甲方严格禁止甲方经办人员的任何商业贿赂行为。甲方经办人员发生本条第二款所列示的任何一种行为，都是违反甲方公司制度的，都将受到甲方公司制度和国家法律的惩处。</div>
                <div class="ints">（四）本公司（甲方）郑重提示：本公司（甲方）反对乙方或乙方经办人员为了本合同之目的与本合同以外的任何第三方发生本条款第（二）条所列示的任何一种行为，该等行为都是违反国家法律的行为，并将受到国家法律的惩处。</div>
                <div class="ints">（五）如因一方或一方经办人员违反商业贿赂条款之规定，给对方造成损失的，应承担损害赔偿责任。</div>
                <div class="ints">（六）本条所称“其他相关人员”是指甲乙方经办人员以外的与合同有直接或间接利益关系的人员，包括但不仅限于合同经办人员的亲友。</div>
                <div class="bold ints">二、反虚假宣传条款</div>
                <div class="ints">&nbsp;&nbsp;甲、乙双方均清楚并愿意严格遵守中华人民共和国《著作权法》、《商标法》、《专利法》、《反不正当竞争法》等知识产权类、民法典及广告法等相关法律的规定，双方均有权就本协议所约定事项以约定方式在约定范围内进行真实、合理的使用或宣传，但不得涉及合同所约定的保密内容。为避免商标侵权及不当宣传等风险的发生，双方均同意，在使用对方的商标、品牌、企业名称等进行宣传前，均须获得对方事先的书面认可，否则，不得进行此类使用或宣传。双方在此承诺，会积极响应对方提出的就合作事项的合理使用或宣传申请。双方均承认，未经对方事先书面同意而利用其商标、品牌及企业名称等进行商业宣传；虚构合作事项；夸大合作范围、内容、效果、规模、程度等，均属对本协议的违反，并可能因虚假宣传构成不正当竞争，守约方或被侵权人将保留追究相应法律责任的权利。</div>
            </div>
            <!-- 《平安银行数字口袋协议》 -->
            <div class="agreement_content" v-show="!isActive">
                <div class="hed">
                    <h2>《平安数字用户服务协议》</h2>
                    <div class="last">（V1.1版）</div>
                </div>
                <!-- <div class="ints">甲方：平安银行股份有限公司</div>
                <div class="ints">乙方: （反显会员名称）</div>
                <div class="title">【特别提示】</div> -->
                <div class="ints">&nbsp;&nbsp;欢迎您使用平安数字用户服务！</div>
                <div class="ints">&nbsp;&nbsp; “平安数字用户”指注册并使用平安数字用户服务的平台用户，其整合了平安银行及平安集团旗下各机构的网络用户。用户只需一个帐号、一套密码、一次登录，即可轻松实现银行、投资、保险等企业账户管理与资产管理的多种需求，提供一站式便捷服务。</div>
                <div class="ints">&nbsp;&nbsp;下述“平安数字用户”的表述，既包括指代平安数字用户服务，也包括指代平安数字用户服务的提供者。“用户”是指使用平安数字用户相关服务的使用人，在本协议中更多地称为“您”。</div>
                <div class="ints">&nbsp;&nbsp;在使用平安数字用户服务之前，请您务必审慎阅读、充分理解《平安数字用户服务协议》（以下简称“本协议”），特别是限制或免除责任的相应条款、法律适用和争议解决条款。限制或免除责任的相应条款以加粗形式提示您注意。未成年人应在法定监护人的陪同下阅读。您勾选“阅读并同意《平安数字用户服务协议》”即表示您确认对本协议全部条款含义已充分理解并完全接受。</div>
                <div class="bold ints">&nbsp;&nbsp; 您同意，平安数字用户及相关服务平台有权在必要时修改本协议条款并按法律规定公示，您可以在相关服务页面查阅最新版本的协议条款。本协议条款变更后，如果您继续使用平安数字用户提供的服务，即视为已接受修改后的协议。如果您不接受修改后的协议，您应当停止使用平安数字用户提供的服务。</div>
                <div class="bold ints">&nbsp;&nbsp;第一条 协议的范围</div>
                <div class="bold ints">&nbsp;&nbsp;第二条 用户使用规则</div>
                <div class="bold ints">&nbsp;&nbsp;第三条 用户的禁止行为</div>
                <div class="bold ints">&nbsp;&nbsp;第四条 服务的变更、中断或终止</div>
                <div class="bold ints">&nbsp;&nbsp; 第五条 授权条款</div>
                <div class="bold ints">&nbsp;&nbsp;第六条 免责条款</div>
                <div class="bold ints">&nbsp;&nbsp;第七条 知识产权声明</div>
                <div class="bold ints">&nbsp;&nbsp;第八条 隐私权保护规则</div>
                <div class="bold ints">&nbsp;&nbsp;第九条 法律效力</div>
                <div class="bold ints">&nbsp;&nbsp;第十条 法律适用和争议解决</div>
                <div class="bold ints">第一条 协议的范围</div>
                <div class="ints">本协议是您与平安数字用户服务的提供者平安银行股份有限公司（以下简称“银行”）之间关于您登录、使用平安数字用户服务（包括但不限于PC端、移动端设备等方式登陆并使用平安数字用户服务）所订立的协议。</div>
                <div class="ints">您声明并承诺您是依据中华人民共和国大陆地区法律设立并有效存续的有限责任公司/股份有限公司/其他形式的组织或为具有完全民事行为能力的自然人，具有一切必要的权利及行为能力订立及履行本协议项下的所有义务和责任；本协议一经生效即对其具有法律约束力。</div>
                <div class="bold ints">第二条 用户使用规则</div>
                <div class="ints">一、用户注册</div>
                <div class="ints">1、您在注册或使用平安数字用户服务时可能需要提供一些必要的信息。您须保证所填写的注册表和所提供的资料真实、准确、完整，否则无法使用本服务或在使用过程中受到限制。对于因您提供的信息不真实、不准确或不完整导致的损失由您承担。</div>
                <div class="ints">2、平安数字用户及相关服务平台有权根据您所提供注册资料的真实性、准确性、完整性以及是否符合平安数字用户及相关服务平台规定的其他条件，决定您的注册成功与否。</div>
                <div class="ints">3、用户在使用平安数字用户的服务过程中，所提供的注册信息发生变动，应及时进行变更。</div>
                <div class="ints">4、您自愿注册并使用平安数字用户，确认以平安数字用户服务的技术操作方式管理金融或非金融产品账户。</div>
                <div class="ints">5、未成年人在注册使用平安数字用户的服务前，应事先取得监护人的同意。监护人的同意包括但不限于口头的、书面的或者其他形式。监护人应承担未成年人在网站隐私权保护的首要责任，且未成年人及其监护人应承担使用本服务而导致的一切后果。</div>
                <div class="ints">6、平安数字用户一经注册，即可在平安集团旗下相关服务平台、合作伙伴等平台中通行使用。在用平安数字用户帐号登录并使用其他平台提供的产品或服务时，除遵守本协议约定外，还应遵守该等平台服务协议的约定、平台公布的规则以及有关正确使用平台服务的说明和操作指引。平安数字用户和其他平台对可能出现的纠纷在法律规定和约定的范围内各自承担责任。</div>
                <div class="ints">7、平安数字用户及相关服务平台为用户提供金融及非金融服务，为客户体验及功能服务考虑，您在不同平台入口注册时被要求填写的内容可能不完全一致，因此，在使用更高级别服务时，为了给您提供更好的服务及为了您的帐号安全，可能需要您补充完整信息后方可使用。</div>
                <div class="ints">二、用户名和密码安全</div>
                <div class="ints">您须妥善保管本人的用户名和密码，及装载和使用平安数字用户应用的设备。凡使用该用户名和密码的行为，平安数字用户及相关服务平台视为您本人的操作，操作所产生的电子信息记录均为平安数字用户及相关服务平台行为的有效凭据。您对利用该用户名和密码所进行的一切活动负全部责任。</div>
                <div class="ints">您应按照机密的原则设置和保管密码，包括但不限于：</div>
                <div class="ints">1、避免使用生日、电话号码等与本人明显相关的数字作为密码；</div>
                <div class="ints">2、收到初始密码后应通过登录平安数字用户及相关服务平台尽快重新设置密码；</div>
                <div class="ints">3、应定期更换密码并牢记；</div>
                <div class="ints">4、在设备上输入密码时，应防止旁人或不安全设备窥视；</div>
                <div class="ints">5、不应在网吧等公众场所的或他人的电脑或网络设备上使用平安数字用户及相关服务平台；</div>
                <div class="ints">6、结束使用平安数字用户服务，应选择安全退出方式终止当前服务；</div>
                <div class="ints">7、发现密码泄漏、遗忘，应及时办理密码重置手续。</div>
                <div class="ints">三、暂停和注销帐号</div>
                <div class="ints">长期不使用平安数字用户，应注销平安数字用户的帐号。</div>
                <div class="ints">您可直接登录平安数字用户及相关服务平台注销平安数字用户及变更注册信息等操作。注销平安数字用户帐号后，其用户资格将被注销，须重新申请注册平安数字用户方可使用。</div>
                <div class="bold ints">第三条 用户的禁止行为</div>
                <div class="ints">一、您使用平安数字用户服务时，禁止从事以下行为，平安数字用户相关的系统记录可作为实施该行为的证据：</div>
                <div class="ints">1、发布、传送、传播、储存危害国家安全统一、破坏社会稳定、违反公序良俗、侮辱、诽谤、淫秽、暴力以及任何违反国家法律法规的内容；</div>
                <div class="ints">2、发布、传送、传播、储存侵害他人知识产权、商业秘密等合法权利的内容；</div>
                <div class="ints">3、恶意虚构事实、隐瞒真相以误导、欺骗他人；</div>
                <div class="ints">4、发布、传送、传播广告信息及垃圾信息；</div>
                <div class="ints">5、将有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序等资料，加以上载、张贴、发送电子邮件或以其他方式传送；</div>
                <div class="ints">6、恶意登录、注册，恶意使用系统功能及服务；干扰或破坏平安数字用户服务或与平安数字用户服务相连的服务器和网络，或不遵守平安数字用户服务网络使用的规定；</div>
                <div class="ints">7、恶意诋毁或损害平安数字用户相关的声誉、利用平安数字用户身份从事违反国家法律法规活动等行为；</div>
                <div class="ints">8、违反任何相关的中国法律、行政法规、规章、条例等其他具有法律效力的规范。</div>
                <div class="ints">二、如果平安数字用户及相关服务平台发现或收到他人举报您发布的信息违反本条约定，平安数字用户及相关服务平台有权进行独立判断并采取技术手段予以删除、屏蔽或断开链接。同时，平安数字用户及相关服务平台有权视用户的行为性质，采取包括但不限于暂停或终止服务，限制、冻结或终止帐号使用等措施并追究法律责任。</div>
                <div class="bold ints">第四条 服务的变更、中断或终止</div>
                <div class="ints">一、您理解，平安数字用户及相关服务平台需要定期或不定期地对提供服务的平台（如互联网网站、移动网络等）或相关的设备进行检修或者维护，或对平安数字用户及相关服务平台系统进行升级、改造。如因此类情况而造成服务在合理时间内的中断，平安数字用户及相关服务平台无需为此承担任何责任。但平安数字用户及相关服务平台应尽可能事先进行通告并根据情况在相应服务页面提前作出通知。</div>
                <div class="bold ints">二、如发生下列任何一种情形，平安数字用户及相关服务平台有权不经通知而中断或终止向您提供本协议项下的服务而无需对您或任何第三方承担任何责任：</div>
                <div class="ints">1、根据法律规定您应提交真实信息，而您提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；</div>
                <div class="ints">2、您违反相关法律法规或本协议的约定；</div>
                <div class="ints">3、按照法律规定或主管部门的要求；</div>
                <div class="ints">4、出于安全的原因或其他必要的情形。</div>
                <div class="ints">三、您有责任自行备份存储在本服务中的数据。如果您的服务被终止，平安数字用户及相关服务平台可以从服务器上永久地删除您的数据，但法律法规另有规定的除外。服务终止后平安数字用户及相关服务平台没有义务向您返还数据。</div>
                <div class="bold ints">第五条 授权条款</div>
                <div class="ints">&nbsp;&nbsp;您同意：授权平安集团，除法律另有规定之外，将您提供给平安集团的信息、享受平安集团服务产生的信息（包括本条款签署之前提供和产生的信息）以及平安集团根据本条款约定查询、收集的信息，用于平安集团及其因服务必要委托的合作伙伴为您提供服务、推荐产品、开展市场调查与信息数据分析。</div>
                <div class="ints">&nbsp;&nbsp;您授权平安集团，除法律另有规定之外，基于为您提供更优质服务和产品的目的，向平安集团因服务必要开展合作的伙伴提供、查询、收集您的信息。</div>
                <div class="ints">&nbsp;&nbsp;前述“信息”可能包括但不限于企业名称、企业证件类型、企业证件号码、法人姓名、法人身份证件号码、手机号码、联系地址、电子邮件地址等。您的身份信息、财产信息、账户信息、信用信息、交易信息、医疗健康信息、行踪轨迹信息、电子设备信息、电子设备操作日志及其他与您相关的信息。</div>
                <div class="ints">&nbsp;&nbsp;为确保您信息的安全，平安集团对上述信息负有保密义务，并尽最大努力采取各种措施保证信息安全。本条款自本协议签署时生效，具有独立法律效力，不受协议成立与否及效力状态变化的影响。</div>
                <div class="ints">&nbsp;&nbsp;本条所称“平安集团”是指中国平安保险（集团）股份有限公司及其直接或间接控股的公司，以及中国平安保险（集团）股份有限公司直接或间接作为其单一最大股东的公司。</div>
                <div class="ints">&nbsp;&nbsp;如您对本协议存在任何意见或建议，您可通过客服电话（投诉热线95511-3-8、95511-2-8（信用卡））、官方网站（http://bank.pingan.com/）“在线客服”、 平安数字口袋APP“在线客服”、平安口袋银行APP“在线客服”、投诉电子邮箱callcenter@pingan.com.cn，或平安银行各营业网点进行咨询和反馈。我行受理您的问题后，将在时效内核实并为您提供解决方案。</div>
                <div class="bold ints">第六条 免责条款</div>
                <div class="bold ints">您理解并同意，平安数字用户在以下情况下不承担责任（包括但不限于）：</div>
                <div class="ints">一、平安数字用户不对本服务提供任何形式的保证，包括但不限于本服务符合您的需求，本服务不受干扰、及时提供或免于出错。</div>
                <div class="ints">二、平安数字用户的合作单位所提供的服务内容及品质由该合作单位自行负责。</div>
                <div class="ints">三、平安数字用户不保证为向用户提供便利而设置的外部链接的准确性和完整性，同时对于该等外部链接指向的不由平安数字用户实际控制的任何网页上的内容，平安数字用户不承担任何责任。</div>
                <div class="ints">四、对于本协议第四条造成的服务变更、中断或终止，平安数字用户不承担责任。</div>
                <div class="ints">五、因以下情况没有正确执行您提交的平安数字用户操作指令而造成损失的，平安数字用户不承担任何责任：</div>
                <div class="ints">1、指令信息不明、存在乱码或不完整等；</div>
                <div class="ints">2、您所拥有的产品或服务发生失效、终止等情况；</div>
                <div class="ints">3、其他平安数字用户无过失的情况。</div>
                <div class="ints">六、对于受到计算机病毒、木马或其他恶意程序、黑客攻击所造成的损失。</div>
                <div class="ints">七、您须安全使用帐号，妥善保存平安数字用户的用户名、密码，对您保管不善造成的损失平安数字用户及相关服务平台不承担责任。</div>
                <div class="ints">八、如果您在共享环境下或在电脑被远程监控的情况下登录平安数字用户及相关服务平台，由此造成的损失平安数字用户及相关服务平台不承担责任。</div>
                <div class="ints">九、平安数字用户及相关服务平台不允许您使用他人身份信息注册，否则，因此产生的法律责任将由您本人承担，给他人造成损失的，您应给予赔偿。</div>
                <div class="ints">十、如您使用他人帐号，须事先获得他人同意。一旦输入正确的用户名和密码，平安数字用户及相关服务平台即视您为已获得他人同意。您与他人之间因此产生的任何纠纷与平安数字用户及相关服务平台无关，对您或他人的损失平安数字用户及相关服务平台不承担责任，并且如造成平安数字用户及相关服务平台损失，平安数字用户及相关服务平台将保留追索的权利。</div>
                <div class="ints">十一、如果平安数字用户将帐号详细信息输入其他任何服务系统而不是输入平安数字用户相关网页安全登录系统，由此造成的损失平安数字用户及相关服务平台不承担责任。</div>
                <div class="ints">十二、平安数字用户通过移动通讯设备登录相关网站和使用相关服务，因通讯过程中导致信息的不完整、丢失或被第三方拦截等不可归责于平安数字用户及相关服务平台的情形，平安数字用户及相关服务平台不承担责任。</div>
                <div class="ints">十三、因无法预见、无法避免且无法克服的不可抗力事件，导致平安数字用户及相关服务平台在本协议项下的义务无法履行或无法按时履行，平安数字用户及相关服务平台在法律允许的范围内免责。</div>
                <div class="ints">十四、如果平安数字用户及相关服务平台存在投入使用时的科学技术水平尚不能发现的缺陷，由此造成的损失平安数字用户及相关服务平台不承担责任。</div>
                <div class="bold ints">第七条 知识产权声明</div>
                <div class="ints">一、平安数字用户及相关服务平台在本服务中所包含的内容（包括但不限于网页、文字、图片、音频、视频、图表等）及提供本服务时所依托软件的著作权、专利权及其他知识产权均归平安银行及关联公司所有。平安数字用户及相关服务平台在本服务中所使用的“平安”、“平安数字用户”等商业标识，其商标权和相关权利归平安集团所有。</div>
                <div class="ints">二、本服务包含的内容的知识产权均受到法律保护，您若侵犯平安数字用户相关知识产权将承担损害赔偿责任。</div>
                <div class="bold ints">第八条 隐私权保护规则</div>
                <div class="ints">平安数字用户及相关服务平台承诺采取严格的隐私政策，尊重并保护用户隐私。平安数字用户及相关服务平台将按照本隐私权保护规则（以下简称“本规则”）收集、使用及共享您的个人信息。本规则包含了我们收集、存储、保护、使用和共享您的个人信息的条款，建议您完整地阅读本规则，以帮助您了解维护自己隐私权的方式。</div>
                <div class="ints">一、信息收集的范围</div>
                <div class="ints">当您使用平安数字用户服务时，您会向我们主动提供一些信息，您同意并许可我们收集您的一些信息，以向您提供服务。</div>
                <div class="ints">我们收集信息的范围主要包括：</div>
                <div class="ints">1、为了遵守法律法规的要求，以及基于提供服务及改进服务质量的合理需要，我们需要识别您的身份。在您注册并使用平安数字用户服务时，您可能要向我们提供一些个人身份资料及企业信息，例如个人姓名、个人证件类型、个人证件号码、手机号码、联系地址、电子邮件地址和企业名称、企业证件类型、企业证件号码、法人姓名、法人身份信息等。</div>
                <div class="ints">2、除上述信息外，我们为向您提供更个性化、更便捷的服务的需要而收集您及企业的其他信息，包括但不限于年龄、生日、职业、教育程度、企业所属行业、企业所在地、企业规模等，以及其他资料如使用的语言种类、喜好等。</div>
                <div class="ints">3、当您访问平安数字用户及相关服务平台网站及移动设备客户端时，我们可能会记录您操作的相关信息以及与平安数字用户及相关服务平台服务相关的日志信息，这些信息可帮助我们向您提供精准服务并更好地保护您的帐号安全。</div>
                <div class="ints">二、收集信息的目的</div>
                <div class="ints">您理解并同意，平安数字用户及相关服务平台可能将用户所提供的个人及企业信息用于以下一项或多项目的：</div>
                <div class="ints">1、更准确的身份确认；</div>
                <div class="ints">2、处理用户购买商品及服务的要求；</div>
                <div class="ints">3、满足用户使用各类网上服务、移动通讯服务及其他服务的要求；</div>
                <div class="ints">4、更好的用户服务和用户沟通，包括项目研究及计划发展、向用户发送相关新闻和服务信息、为用户提供有关优惠及推荐产品；</div>
                <div class="ints">5、预防或禁止非法的活动；</div>
                <div class="ints">6、经您许可的其他用途。</div>
                <div class="ints">三、保护和存储信息</div>
                <div class="ints">为保持数据的准确性，防止擅自入侵及确保个人及企业资料的正确使用，平安数字用户及相关服务平台取了适当的管理措施以保障网站收集个人及企业资料的保密性。平安数字用户及相关服务平台采用了安全技术措施来保护数据，建立严格的信息存储和使用制度规范，并对可能接触到您的信息的人员也采取了严格管理。</div>
                <div class="ints">四、信息的共享及披露</div>
                <div class="ints">我们对您的信息承担保密义务，未经您允许的情况下不公开您的个人及企业信息。您同意，我们在下列情况下将您的信息与第三方共享：</div>
                <div class="ints">1、获得您的同意或授权；</div>
                <div class="ints">2、为提供您要求的服务所必需，某些服务需由合作伙伴（包括但不限于提供相关技术支持的公司、快递公司、提供奖品的公司）提供或共同提供，为了向您提供此类服务，平安数字用户及相关服务平台有必要与提供该服务的合作伙伴共享完成该服务所需要的用户个人及企业信息；</div>
                <div class="ints">3、平安数字用户及相关服务平台与第三方共同进行推广活动，在推广活动中所收集的全部或部分个人及企业信息可能会与该第三方分享；</div>
                <div class="ints">4、根据法律法规或政府的强制性规定，必须向有关司法或政府部门提供用户个人及企业信息；</div>
                <div class="ints">5、为完成合并、分立、收购或资产转让；</div>
                <div class="ints">6、为防止用户本人、他人的合法权益或社会公共利益受到重大危害。</div>
                <div class="ints">五、对第三方责任的声明</div>
                <div class="ints">平安数字用户及相关服务平台因服务必要，可能会将相关网站链接至平安集团开展合作的伙伴的网站（统称“第三方网站”）。本《隐私权保护规则》并不适用于这些第三方网站，因为它们不受平安数字用户及相关服务平台控制。如果您使用平安数字用户及相关服务平台提供的链接访问第三方网站，这些网站的运营商可能会收集您的个人信息。平安数字用户及相关服务平台尽力确保所有链接的第三方网站采用同等的个人信息保护措施，但是平安数字用户及相关服务平台不对这些第三方网站上的活动、隐私权政策或隐私保护水平承担任何法律或其他责任。</div>
                <div class="bold ints">第九条 法律效力</div>
                <div class="ints">一、用户使用平安数字用户及相关平台的服务即视为用户已阅读本协议并接受本协议的约束。</div>
                <div class="ints">二、本协议所有条款的标题仅为阅读方便，本身并无实际含义，不能作为本协议含义解释的依据。</div>
                <div class="ints">三、本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。</div>
                <div class="bold ints">第十条 法律适用和争议解决</div>
                <div class="ints">一、本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不含冲突法），如无相关法律规定的，则应参照通用国际商业惯例和/或行业惯例。</div>
                <div class="ints">二、您与平安数字用户及相关服务平台在履行本协议的过程中，如发生争议，应协商解决。协商不成的，均提请深圳仲裁委员会按照该会仲裁规则进行仲裁。仲裁裁决是终局的，对双方均有约束力。</div>
            </div>
            <div class="agree_wrap">
                <el-checkbox v-model="agreeFlag" @change="agree">我已阅读并同意以上协议-</el-checkbox>
                <!-- <input type="checkbox" :checked="agreeFlag" /> -->
                <!-- <span class="agree_selected iconfont icon-finish" @click="agree" /> -->
                <span class="text" @click="handle(true)">《产业结算通协议》</span>
                <span class="text" @click="handle(false)">《平安银行数字口袋协议》</span>
            </div>
            <div class="bank_bottom_Form">
                <!-- 打款验证 end -->
                <el-form label-width="120px" v-show="isidentity == 1">
                    <div class="next">
                        <div @click="next">下一步</div>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>
  
<script>
import { ElInput, ElButton, ElMessage, ElScrollbar } from "element-plus";
import MemberTitle from '../../../components/MemberTitle';
import { useRouter,useRoute } from 'vue-router';
import { ref, onMounted, getCurrentInstance } from 'vue';
import { createLogger, useStore } from 'vuex'
// import { Store } from "vuex";
export default {
    name: "AccountNow2",
    components: {
        MemberTitle
    },
    setup() {
        const { proxy } = getCurrentInstance()
        const agreeFlag = ref(false);//同意注册协议标识，默认不同意
        const isActive = ref(true);
        const bankList = ref({});
        const route = useRoute();
        let isidentity = ref(1) //判断绑定页面显示隐藏
        const router = useRouter();
        const store = useStore();
        const memberTrueName= ref("");
        const init=()=>{
            //proxy.$get('/v3/member/front/member/memberInfo').then(res => {
            //    if (res.state == 200) {
            //        memberTrueName.value= res.data.memberTrueName
            //    } else {
            //        ElMessage.warning(res.msg)
            //    }
            //})
           memberTrueName.value= store.state.memberInfo.memberTrueName
        }
        const agree=(val)=>{
            agreeFlag.value= val
        }
        const handle=(val)=>{
            val?isActive.value= true:isActive.value= false
        }
        const next=()=>{
            if(!agreeFlag.value) {return  ElMessage.warning('请先阅读并同意以下协议')}
            router.push('/member/AccountNow2?mobile='+route.query.mobile)
        }
        init()
        return {
            agreeFlag,
            isActive,
            isidentity,
            memberTrueName,
            agree,
            handle,
            next,
            init
        };
    }
};
</script>
  
<style lang="scss">
.bank_bottom_Form {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    }
    input[type='number'] {
    -moz-appearance: textfield;

    }
    .el-cascader {
        width: 100%;
    }

    .el-input__inner {
        height: 31px;
    }

    .el-form-item {
        margin-bottom: 10px;

        .el-select {
            width: 100%;
        }
    }
}
.sld_AccountNow{
    .search_dialog::-webkit-scrollbar {
        width: 10px;
    }
    .search_dialog::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        opacity: 0.2;
        background: #909399;
    }
    .search_dialog::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        // background: #909399;
    }
    .search_dialog {
        // position: absolute;
        background-color: white;
        z-index: 9999;
        min-width: 335.324px;
        max-height: 150px;
        color: var(--el-text-color-regular);
        border-radius: var(--el-border-radius-base);
        background: white;
        padding: 6px 0;
        box-shadow: var(--el-box-shadow-light);
        box-sizing: border-box;
        overflow: auto;
         

        div {
            width: 100%;
            height: 34px;
            line-height: 34px;
            cursor: pointer;
            padding-left: 20px;

            &:hover {
                background-color: var(--el-background-color-base);

            }
        }
    }
}


</style>
<style lang="scss" scoped>
.scrollbar-demo-item {
    height: 50px;
    text-align: center;
    border-radius: 4px;
    color: var(--el-color-primary);
    width: 100%;
}



.search {
    // position: relative;
    position: absolute;

    
}

.bank_bottom_Form {
    margin-left: 80px;
    // margin-top: 40px;
    position: relative;

    .timeOut {
        position: absolute;
        top: 5px;
        right: 390px;
        width: 109px;
        height: 30px;
        border-radius: 4px;
        background-color: rgba(240, 39, 39, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        text-align: center;
        line-height: 30px;
    }

    .next2 {
        width: 70px;
        background-color: rgba(240, 39, 39, 1);
        height: 30px;
        border-radius: 5px;
        text-align: center;
        line-height: 30px;
        color: #fff;
        margin: 0 auto;
        position: absolute;
        left: 120px;
        top: 90px;
        cursor: pointer;
    }

    .next {
        width: 70px;
        background-color: $colorMain;
        height: 30px;
        border-radius: 5px;
        text-align: center;
        line-height: 30px;
        color: #fff;
        // margin: 0 auto;
        margin-top: 40px;
        margin-left: 130px;
        cursor: pointer;
    }
}

.bank_bottom_Form1 {
    margin-top: 20px;
    margin-left: 100px;
    height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #999;

    .info {
        font-size: 16px;
        margin-top: 20px;
    }

    .next {
        border: 1px solid rgba(187, 187, 187, 1);
        width: 110px;
        height: 50px;
        border-radius: 5px;
        text-align: center;
        line-height: 50px;
        margin: 0 auto;
        cursor: pointer;
        margin-top: 40px;
        color: rgba(16, 16, 16, 1);
        font-size: 16px;
    }
}

.sld_AccountNow {
    width: 1020px;
    float: left;
    margin-left: 10px;

    .unbinding {
        height: 40px;
        line-height: 40px;
        text-align: right;
        width: 307px;
        color: rgba(30, 119, 240, 1);
        cursor: pointer;
    }

    .sld_bankCard_con {
        background: #fff;
        height: 790px;
        // padding-bottom: 50px;
        padding: 0 20px;

        .bank_top {
            height: 70px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            div {
                &:nth-child(1) {
                    line-height: 70px;
                    color: #1C1C1C;
                    font-size: 16px;
                    font-weight: 600;
                    text-align: left;
                }
            }
        }
        .agreement_content{
            letter-spacing:1px;
            height: 600px;
            overflow-y: auto;
            font-size: 16px;
            .hed{
                text-align: center;
                margin-top: 20px;
                .last{
                    margin-top: 10px;
                }
            }
            .bold{
                font-weight: 600;
            }
            .ints{
                margin-top: 20px;
            }
            .title{
                margin-top: 30px;
            }
        }
    }
    .agree_wrap{
        margin-top: 20px;
        .text{
            cursor: pointer;
            color: $colorMain;
        }
        .text:hover{
            color: $colorMain2;
        }
    }
}

.bank_center {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;

    .bank_state {
        display: flex;
        align-items: center;
    }

    .line {
        width: 241px;
        border: 1px solid rgba(187, 187, 187, 1);
        margin: 0 15px;
    }

    .red_state {
        background-color: rgba(240, 39, 39, 1);
        color: rgba(255, 255, 255, 1);
    }

    .white_state {
        position: relative;
        width: 103px;
        height: 103px;
        line-height: 103px;
        // background-color: rgba(240, 39, 39, 1);
        // color: rgba(255, 255, 255, 1);
        // color: black;
        font-size: 20px;
        text-align: center;
        font-family: Roboto;
        border-radius: 50%;
        border: 1px solid rgba(187, 187, 187, 1);

        span {
            position: absolute;
            left: 50%;
            bottom: -50px;
            transform: translate(-50%, -50%);
            line-height: 20px;
            font-size: 14px;
            color: black;
            width: 100px;
        }
    }


}
</style>